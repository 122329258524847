import React, { Fragment, useState, useMemo, useEffect } from "react";
import "./_ContactUs.scss";
import InputField from "components/InputField/InputField";
import SubmitButton from "components/SubmitButton/SubmitButton";
import { useUserContext } from "contexts";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import Data from "assets/Data.json";
import { changeTitle } from "helpers/helperFunctions";
import { validateEmail } from "helpers/helperFunctions";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "./phone.css";
export const ContactUs = (props) => {
  const initialState = {
    name: "",
    email: "",
    phone_no: "",
    message: "",
  };
  const history = useHistory();
  const UserContext = useUserContext();
  const [userContactUs, setuserContactUsData] = useState(initialState);
  const [message, setMessage] = useState(undefined);
  const [error, setError] = useState({
    email: undefined,
    contact: undefined,
    message: undefined,
  });
  const [captcha, setCaptcha] = useState(undefined);
  const userProps = useMemo(() => {
    return {
      contactUs: UserContext.contactUs,
      error: UserContext.error,
    };
  }, [UserContext]);
  const { contactUs } = userProps;
  function handleChange(e) {
    setuserContactUsData({ ...userContactUs, [e.target.name]: e.target.value });
  }
  function changePhone(value) {
    setuserContactUsData({ ...userContactUs, phone_no: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const body = {
      name: userContactUs.name,
      email: userContactUs.email,
      phone_no: userContactUs.phone_no,
      message: userContactUs.message,
    };
    if (captcha) {
      let contactform = await contactUs(body);
      if (contactform.status === 200) {
        setMessage("Contact request submitted");
        setTimeout(() => {
          setMessage(undefined);
        }, 3000);
        setuserContactUsData(initialState);
      }
    }
  }
  function handleCancle() {
    history.push("/");
  }
  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);
  function onChange(value) {
    setCaptcha(value);
  }
  function checkMessage(e) {
    const str = e.target.value;
    var doc = new DOMParser().parseFromString(str, "text/html");
    const isError = Array.from(doc.body.childNodes).some(
      (node) => node.nodeType === 1
    );
    if (isError) setError({ ...error, message: isError });
  }

  return (
    <Fragment>
      <div className="contact-us-container">
        <div className="contact-us-inner">
          <span className="inner-title">Get Captions</span>

          <div className="form-container">
            {message && <Alert severity="success">{message}</Alert>}
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="contact-form">
                <InputField
                  type="text"
                  id="name"
                  name="name"
                  label="Name / Company"
                  value={userContactUs.name}
                  onChange={(e) => handleChange(e)}
                  autoFocus
                />
                <InputField
                  type="email"
                  id="email"
                  name="email"
                  label="Email"
                  className="user_email"
                  value={userContactUs.email}
                  onChange={(e) => {
                    setError({ ...error, email: undefined });
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    const isError = validateEmail(e.target.value);
                    setError({ ...error, email: isError });
                  }}
                  error={error.email}
                  helperText="Incorrect email"
                />
                {/* <span className="phone-label">Phone</span> */}
                <PhoneInput
                  className="phone-number"
                  name="phoneNumber"
                  type="text"
                  country={"us"}
                  enableAreaCodes={true}
                  onlyCountries={["us"]}
                  inputProps={{
                    name: "phone",
                    country: "us",
                    required: true,
                  }}
                  value={userContactUs.phone_no}
                  onChange={(e) => changePhone(e)}
                  containerStyle={{
                    color: "#ffffff",
                    width: "100%",
                    "margin-top": "20px",
                    "border-bottom": "1px solid #347cff",
                  }}
                  inputStyle={{
                    width: "100%",
                    background: "transparent",
                    border: "none",
                    color: "#ffffff",
                  }}
                  dropdownStyle={{}}
                  required
                />

                <InputField
                  type="text"
                  id="message"
                  name="message"
                  label="Message"
                  placeholder={Data.contactUsMsg}
                  minRows={4}
                  multiline
                  value={userContactUs.message}
                  onChange={(e) => {
                    setError({ ...error, message: undefined });
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    checkMessage(e);
                  }}
                  inputProps={{
                    maxLength: 500,
                  }}
                  error={error.message}
                  helperText="Enter valid message"
                />
                <div className="message-size">
                  {userContactUs.message.length}/500
                </div>
                <div>
                  <ReCAPTCHA
                    sitekey="6LdnQGocAAAAANLoxZ8dqSIj6yBr9nkWRo8Rmkcl"
                    onChange={onChange}
                    theme="dark"
                  />
                </div>
                <div className="submit-container">
                  <SubmitButton
                    type="button"
                    title="cancel"
                    onClick={() => handleCancle()}
                  />
                  <SubmitButton type="submit" title="send" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactUs;
