import React, { Fragment, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RequestButton } from "components/RequestButton";
import "./Orders.scss";
import moment from "moment";
import { useDashboardContext } from "contexts";
import { changeTitle } from "helpers/helperFunctions";
export const OrdersDetail = (props) => {
  const history = useHistory();
  const { id } = props;
  const { row } = history.location.state;
  const DashBoardContext = useDashboardContext();

  const dashboardProps = useMemo(() => {
    return {
      state: DashBoardContext.state,
      getOrderDetail: DashBoardContext.getOrderDetail,
    };
  }, [DashBoardContext]);
  const { getOrderDetail, state } = dashboardProps;
  const { orderDetail, orderListLoaded } = state;
  useEffect(() => {
    const service_cust = row.SERVICE_CUST[0];
    if (id) getOrderDetail(id, service_cust);
  }, [id]);
  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);
  return useMemo(() => {
    return (
      <Fragment>
        <div className="orders-container">
          <Fragment>
            <div className="user-title">
              {!orderListLoaded && orderDetail ? orderDetail.TITLE : "-"}
            </div>
            <div className="detail-container">
              <div className="order-detail-item">
                <div>Order #:</div>{" "}
                <div className="value">
                  {!orderListLoaded && orderDetail
                    ? orderDetail.STTWO_NUMBER
                    : "-"}
                </div>
              </div>
              <div className="order-detail-item">
                <div>Service:</div>{" "}
                <div className="value">
                  {!orderListLoaded && orderDetail && orderDetail.Service_Short}
                </div>
              </div>
              <div className="order-detail-item">
                <div>Submission Date:</div>{" "}
                <div className="value">
                  {!orderListLoaded && orderDetail && orderDetail.createdTime
                    ? moment(orderDetail.createdTime).format("YYYY-MM-DD")
                    : "-"}
                </div>
              </div>
              <div className="order-section">
                <div className="order-detail-item">
                  <div>Runtime (minutes): </div>{" "}
                  <div className="value">
                    {!orderListLoaded && orderDetail
                      ? orderDetail["RUN-TIME/UNIT"]
                      : "-"}
                  </div>
                </div>
                <div className="order-detail-item">
                  <div>Proxy Received: </div>{" "}
                  <div className="value">
                    {!orderListLoaded && orderDetail && orderDetail["PROXY URL"]
                      ? "Yes"
                      : "No"}{" "}
                  </div>
                </div>
                <div className="order-detail-item">
                  <div>Template Received: </div>{" "}
                  <div className="value">
                    {!orderListLoaded && orderDetail && orderDetail["TEMPLATE"]
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
              </div>
            </div>
            {!orderListLoaded && orderDetail && (
              <div className="detail-button-order">
                {orderDetail.STATUS === "COMPLETED" ? (
                  <RequestButton
                    title="download files"
                    onClick={() => console.log("object")}
                    className="detail-btn"
                  />
                ) : (
                  <Fragment>
                    <RequestButton
                      title="upload proxy"
                      onClick={() => console.log("object")}
                      className="detail-btn"
                    />

                    <RequestButton
                      title="upload template"
                      onClick={() => console.log("object")}
                      className="detail-btn"
                    />
                  </Fragment>
                )}
              </div>
            )}
          </Fragment>
        </div>
      </Fragment>
    );
  }, [orderDetail, orderListLoaded]);
};

export default OrdersDetail;
