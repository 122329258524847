import { useDashboardContext, useUserContext } from "contexts";
import { changeTitle, formatter } from "helpers/helperFunctions";
import React, { Fragment, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "./Home.scss";

export const Home = (props) => {
  const history = useHistory();
  const DashBoardContext = useDashboardContext();
  const UserContext = useUserContext();
  const dashboardProps = useMemo(() => {
    return {
      state: DashBoardContext.state,
      isLoading: DashBoardContext.loading,
      getCompletedOrders: DashBoardContext.getCompletedOrders,
      getAuthToken: DashBoardContext.getAuthToken,
      getRefreshToken: DashBoardContext.getRefreshToken,
      getInvoices: DashBoardContext.getInvoices,
      getAccountDetail: DashBoardContext.getAccountDetail,
    };
  }, [DashBoardContext]);

  const userProps = useMemo(() => {
    return {
      user_state: UserContext.state,
    };
  }, [UserContext]);
  const { user_state } = userProps;
  const {
    getCompletedOrders,
    state,
    getInvoices,
    getAccountDetail,
    getAuthToken,
    isLoading,
  } = dashboardProps;
  const {
    order_pending,
    order_completed,
    authToken,
    freshbookUserDetail,
    home_detail,
    tokenLoaded,
    invoiceListLoaded,
    orderLoaded,
    homeDetailLoaded,
  } = state;
  const { airtable_customer, freshbook_customerid, company } = user_state;

  useEffect(() => {
    if (!authToken && !tokenLoaded) {
      getAuthToken();
    }
  }, [authToken, tokenLoaded]);
  useEffect(() => {
    if (authToken && !freshbookUserDetail) {
      getAccountDetail(authToken.access_token);
    }
  }, [authToken, freshbookUserDetail]);

  useEffect(() => {
    if (
      !orderLoaded &&
      !order_pending &&
      !order_completed &&
      airtable_customer &&
      home_detail
    ) {
      getCompletedOrders(airtable_customer);
    }
  }, [
    orderLoaded,
    home_detail,
    order_completed,
    order_pending,
    airtable_customer,
  ]);
  useEffect(() => {
    if (
      freshbookUserDetail &&
      !isLoading &&
      !home_detail &&
      !homeDetailLoaded
    ) {
      const { business_memberships } = freshbookUserDetail;
      const account_id = business_memberships[0]["business"]["account_id"];
      if (authToken && account_id) {
        getInvoices(authToken.access_token, account_id, freshbook_customerid);
      }
    }
  }, [
    freshbookUserDetail,
    invoiceListLoaded,
    order_completed,
    order_pending,
    home_detail,
    homeDetailLoaded,
    history,
    isLoading,
  ]);
  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);

  return useMemo(() => {
    return (
      <Fragment>
        <div className="home-container">
          <div className="user-title">{company}</div>
          <div className="detail-container">
            <div className="user-account-item">
              <div>Current Balance:</div>{" "}
              <div className="value">
                {home_detail && home_detail.current_balance
                  ? formatter.format(home_detail.current_balance)
                  : "-"}
              </div>
            </div>
            <div className="user-account-item">
              <div>Payment Due:</div>{" "}
              <div className="value">
                {home_detail && home_detail.payment_due
                  ? formatter.format(home_detail.payment_due)
                  : "-"}
              </div>
            </div>
            <div className="user-account-item">
              <div>Due Date:</div>{" "}
              <div className="value">
                {home_detail && home_detail.due_date
                  ? home_detail.due_date
                  : "-"}
              </div>
            </div>
            <div className="order-section">
              <div className="user-account-item">
                <div>Orders In Progress: </div>{" "}
                <div className="value">
                  {order_pending ? order_pending : "-"}
                </div>
              </div>
              <div className="user-account-item">
                <div>Orders Completed in Period: </div>{" "}
                <div className="value">
                  {order_completed ? order_completed : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }, [order_pending, order_completed, isLoading, home_detail]);
};

export default Home;
