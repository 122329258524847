import Footer from "components/Footer/Footer";
import { NavBar } from "components/NavBar";
//import PageNotFound from 'components/ErrorPages/FourZeroFour';
import { DashboardContextProvider, useUserContext } from "contexts";
import React, { Fragment, useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  Link,
  useLocation
} from "react-router-dom";
import { Auth } from "screens/Auth";
import { ContactUs } from "screens/ContactUs";
import { Dashboard } from "screens/Dashboard";
import { Home } from "screens/Home";
import { Services } from "screens/Services";

export default function Routes() {
  const UserContext = useUserContext();
  const userProps = useMemo(() => {
    return {
      isLogin: UserContext.state.isLogin,
      checkLogin: UserContext.checkLogin,
    };
  }, [UserContext]);
  useEffect(() => {
    userProps.checkLogin();
  }, []);
  const userData = JSON.parse(localStorage.getItem("stt_user_data"));

  const PrivateRoute = ({ component: Component, condition, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        condition ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
  return (
    <Fragment>
      <Router basename="/">
        <div className="parent-component">
          <DashboardContextProvider>
            <NavBar />

            <Switch>
              <div className="main-container">
                <Route
                  exact
                  path="/"
                  render={(props) => {
                    return userData && userData.isLogin ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <Redirect to="/home" />
                    );
                  }}
                />
                <Route path="/auth">
                  <Auth />
                </Route>

                <Route exact path="/home">
                  <Home />
                </Route>
                <Route path="/services">
                  <Services />
                </Route>
                <Route path="/contact-us">
                  <ContactUs />
                </Route>
                <PrivateRoute
                  condition={userData && userData.isLogin}
                  path="/dashboard"
                  component={Dashboard}
                />
              </div>
            </Switch>
          </DashboardContextProvider>
          <Footer />
        </div>
      </Router>
    </Fragment>
  );
}
function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
