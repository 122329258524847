import React, { Fragment, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RequestButton } from "components/RequestButton";
import { useDashboardContext } from "contexts";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Invoice.scss";
import { changeTitle, formatter } from "helpers/helperFunctions";
const useStyles = makeStyles({
  root: {
    background: "#000000",
    color: "#ffffff",
  },
  headerCell: {
    color: "#ffffff",
    borderBottom: "1px solid #8c8c8c",
    fontFamily: "Helvetica",
    fontSize: "16px",
    padding: "5px 0px",
  },
  bodyCell: {
    color: "#ebac2f",
    border: "none",
    fontFamily: "Helvetica",
    fontSize: "16px",
    padding: "5px",
  },
  rowRroot: {
    height: "10px",
  },
  leaderRow: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  leaderCell: {
    display: "flex",
    justifyContent: "center",
    border: "none",
  },
});

export const InvoiceDetail = ({ id }) => {
  const history = useHistory();
  const { row } = history.location.state;
  const classes = useStyles();
  let total = 0;

  const DashBoardContext = useDashboardContext();

  const dashboardProps = useMemo(() => {
    return {
      state: DashBoardContext.state,
      getInvoiceDetail: DashBoardContext.getInvoiceDetail,
    };
  }, [DashBoardContext]);
  const { getInvoiceDetail, state } = dashboardProps;
  const { invoiceDetailList, invoiceDetailLoaded } = state;
  useEffect(() => {
    if (id && !invoiceDetailLoaded) {
      getInvoiceDetail(id);
    }
  }, [id]);
  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);
  return useMemo(() => {
    return (
      <Fragment>
        <div className="invoice-container">
          <div className="invoice-basic">
            <div>
              <div className="invoice-title">Invoice: {row.invoice_number}</div>
              <div className="invoice-due">Due: {row.due_date}</div>
            </div>
            <div className="invoice-status">
              {row.payment_status === "unpaid" ? "Unpaid" : ""}
            </div>
          </div>

          <div className="invoice-list-container">
            <div>
              <Table
                classes={{
                  root: classes.root,
                }}
                className="service-table"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"10%"}
                    >
                      Order #
                    </TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"30%"}
                    >
                      Title
                    </TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"20%"}
                    >
                      Service Type
                    </TableCell>

                    <TableCell
                      className={"dynamic-col"}
                      classes={{ root: classes.headerCell }}
                      align="left"
                      width={"10%"}
                    >
                      Runtime/Qty
                    </TableCell>
                    <TableCell
                      className={"dynamic-col"}
                      classes={{
                        root: classes.headerCell,
                      }}
                      align="center"
                      width={"10%"}
                    >
                      Rate
                    </TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="right"
                      width={"10%"}
                    >
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!invoiceDetailLoaded &&
                    invoiceDetailList &&
                    invoiceDetailList.map((row, index) => {
                      total =
                        total + parseInt(row.runtime) * parseInt(row.rate);
                      return (
                        <TableRow
                          key={index}
                          classes={{ root: classes.rowRroot }}
                        >
                          <TableCell className={classes.bodyCell} align="left">
                            {row.STTWO_NUMBER}
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="left">
                            {row.TITLE}
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="left">
                            {row.SERVICE_SHORT}
                          </TableCell>
                          <TableCell
                            className={"dynamic-col"}
                            classes={{ root: classes.bodyCell }}
                            align="left"
                          >
                            {row["RUN-TIME/UNIT"]}
                          </TableCell>
                          <TableCell
                            className={"dynamic-col"}
                            classes={{ root: classes.bodyCell }}
                            align="center"
                          >
                            {"$" + row["Service Rate"][0]}
                          </TableCell>
                          <TableCell className={classes.bodyCell} align="right">
                            {"$" +
                              parseFloat(
                                parseInt(row["RUN-TIME/UNIT"]) *
                                  row["Service Rate"][0]
                              ).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            {invoiceDetailList && invoiceDetailList.length === 0 && (
              <div className="loader">No Record Found</div>
            )}
            <div className="loader">
              {invoiceDetailLoaded && <CircularProgress />}
            </div>
            <div className="total-section">
              <div>Total</div>
              <div>{formatter.format(row.amount.amount)}</div>
            </div>
            <div className="detail-button-invoice">
              {row.payment_status === "unpaid" && (
                <RequestButton
                  title="pay"
                  onClick={() => console.log("pay")}
                  className="detail-btn"
                />
              )}

              <RequestButton
                title="download pdf"
                onClick={() => console.log("object")}
                className="detail-btn"
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }, [invoiceDetailList, invoiceDetailLoaded]);
};

export default InvoiceDetail;
