import InputField from "components/InputField/InputField";
import SubmitButton from "components/SubmitButton/SubmitButton";
import React, { Fragment, useMemo, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import "./Login.scss";
import { useUserContext } from "contexts";
import { changeTitle } from "helpers/helperFunctions";
export const Login = (props) => {
  const UserContext = useUserContext();
  const history = useHistory();
  const [userData, setUserData] = useState({ email: "", password: "" });
  const userProps = useMemo(() => {
    return {
      state: UserContext.state,
      login: UserContext.login,
      error: UserContext.error,
    };
  }, [UserContext]);
  const { state, error } = userProps;
  function handleChange(e) {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }
  async function handleSubmit(e) {
    e.preventDefault();
    const body = {
      username: userData.email,
      password: userData.password,
    };

    const res = await userProps.login(body);
    if (res.Status && res.Status === 200) {
      history.push("/dashboard");
    }
  }
  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);
  return useMemo(() => {
    return (
      <Fragment>
        <span className="inner-title">Client Login</span>
        <div className="form-container">
          {error && <Alert severity="error">{error}</Alert>}

          <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
            <div className="login-form">
              <InputField
                type="text"
                value={userData.email}
                name="email"
                onChange={(e) => handleChange(e)}
                id="username"
                label="Username / Email"
                required
              />
              <InputField
                type="password"
                value={userData.password}
                name="password"
                onChange={(e) => handleChange(e)}
                id="password"
                label="Password"
                required
              />
              <Link className="forgot-link" to="/auth/forgot-password">
                Forgot Password?
              </Link>
              <div className="submit-container">
                <SubmitButton type="submit" title="Login" />
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }, [state, error, userData]);
};

export default Login;
