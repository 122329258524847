import React, { Fragment, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "screens/Login/Login";
import { ResetPassword, NewPassword } from "screens/ResetPassword";
import "./_Auth.scss";
import { useUserContext } from "contexts";
export const Auth = (props) => {
  const UserContext = useUserContext();
  const userProps = useMemo(() => {
    return {
      state: UserContext.state,
    };
  }, [UserContext]);
  const { state } = userProps;
  return (
    <Fragment>
      <div className="login-container">
        <div className="login-inner">
          <Switch>
            <Redirect exact={true} from="/auth" to="/auth/login" />
            <Route exact path="/auth/login" render={() => <Login />} />
            <Route
              exact
              path="/auth/forgot-password"
              render={() => <ResetPassword />}
            />
            {state.email && state.isOtpSent ? (
              <Route
                exact
                path="/auth/new-password"
                render={() => <NewPassword />}
              />
            ) : (
              <Redirect to="/auth/login" />
            )}
          </Switch>
        </div>
      </div>
    </Fragment>
  );
};

export default Auth;
