import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PayButton from "components/PayButton/PayButton";
import React, { Fragment, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDashboardContext, useUserContext } from "contexts";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitle, formatter } from "helpers/helperFunctions";
import "./Invoice.scss";
import Pagiation from "components/Pagiation/Pagiation";
import moment from "moment";
const useStyles = makeStyles({
  root: {
    background: "#000000",
    color: "#ffffff",
    display: "flex",
    flexDirection: "column",
  },
  titleCell: {
    fontFamily: "Helvetica",
    fontSize: " 14px",
    fontWeight: "700",
  },
  leaderRow: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  leaderCell: {
    display: "flex",
    justifyContent: "center",
    border: "none",
  },
  headerCell: {
    color: "#ffffff",
    fontFamily: "Helvetica",
    fontSize: "16px",
    padding: "5px 10px",
    border: "none",
    fontWeight: "700",
  },
  bodyCell: {
    color: "#ffffff",
    fontFamily: "Helvetica",
    fontSize: "14px",
    border: "none",
    cursor: "pointer",
    padding: "5px 10px",
  },
  mobileRowRoot: {
    border: "1px solid #666666",
    borderRadius: "6px",
    marginTop: "5px",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
  },
  rowRroot: {
    height: "40px",
    border: "1px solid #666666",
    borderRadius: "6px",
    marginTop: "5px",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
  },
});

export const Invoice = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const DashBoardContext = useDashboardContext();
  const dashboardProps = useMemo(() => {
    return {
      state: DashBoardContext.state,
      loading: DashBoardContext.loading,
      getInvoiceList: DashBoardContext.getInvoiceList,
      getAuthToken: DashBoardContext.getAuthToken,
      getAccountDetail: DashBoardContext.getAccountDetail,
    };
  }, [DashBoardContext]);
  const UserContext = useUserContext();
  const userProps = useMemo(() => {
    return {
      user_state: UserContext.state,
    };
  }, [UserContext]);
  const { user_state } = userProps;
  const { freshbook_customerid } = user_state;

  const {
    state,
    getInvoiceList,
    getAuthToken,
    loading,
    getAccountDetail,
  } = dashboardProps;
  const {
    freshbookUserDetail,
    authToken,
    invoiceList,
    invoicePagination,
    invoiceListLoaded,
    tokenLoaded,
  } = state;
  let params = history.location.search;

  useEffect(() => {
    if (!authToken && !tokenLoaded) {
      getAuthToken();
    }
  }, [authToken, tokenLoaded]);
  useEffect(() => {
    if (authToken && !freshbookUserDetail) {
      getAccountDetail(authToken.access_token);
    }
  }, [authToken, freshbookUserDetail]);

  useEffect(() => {
    let params = history.location.search;
    let page = params.split("=")[1];
    if (authToken && freshbookUserDetail && !invoiceListLoaded && !page) {
      const { business_memberships } = freshbookUserDetail;
      const account_id = business_memberships[0]["business"]["account_id"];
      if (account_id && authToken.access_token && freshbook_customerid) {
        getInvoiceList(
          authToken.access_token,
          account_id,
          1,
          freshbook_customerid
        );
      }
    }
  }, [
    authToken,
    freshbookUserDetail,
    invoiceListLoaded,
    history.location.search,
  ]);
  useEffect(() => {
    let params = history.location.search;
    let page = params.split("=")[1];

    if (freshbookUserDetail && page) {
      const { business_memberships } = freshbookUserDetail;
      const account_id = business_memberships[0]["business"]["account_id"];

      if (page && authToken && account_id) {
        getInvoiceList(
          authToken.access_token,
          account_id,
          page,
          freshbook_customerid
        );
      }
    }
  }, [authToken, freshbookUserDetail, history.location.search]);
  const onPageChange = (page) => {
    history.push({
      pathname: "/dashboard/invoices",
      search: `?page=${page}`,
    });
  };
  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);
  return useMemo(() => {
    return (
      <Fragment>
        <div className="invoice-container">
          <div className="invoice-list-container">
            <div className="mobile-invoice">
              <Table
                classes={{
                  root: classes.root,
                }}
                className="service-table"
                aria-label="simple table"
              >
                <TableBody>
                  {loading && (
                    <TableRow className={classes.leaderRow}>
                      <TableCell
                        className={classes.leaderCell}
                        align="left"
                        width={"100%"}
                        height={"200px"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    invoiceListLoaded &&
                    invoiceList &&
                    invoiceList.map((row, index) => (
                      <TableRow
                        key={index}
                        classes={{ root: classes.mobileRowRoot }}
                        onClick={() =>
                          history.push(
                            "/dashboard/invoices-detail/" + row.invoice_number,
                            {
                              row,
                            }
                          )
                        }
                      >
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"60%"}
                        >
                          <div>
                            {/* <div>Customer Id: {row.customerid}</div> */}
                            <div>Invoice: {row.invoice_number}</div>
                            <div>Status: {row.payment_status}</div>
                            <div>
                              Due Date:{" "}
                              {moment(row.due_date).format("YYYY-MM-DD")}
                            </div>

                            <div>
                              Ammount: {formatter.format(row.amount.amount)}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"30%"}
                        >
                          {row.payment_status === "unpaid" ? (
                            <div className="pay">
                              <PayButton
                                className="pay-button"
                                title={"Pay"}
                                onClick={() => console.log("pay")}
                              />
                            </div>
                          ) : (
                            <div className="paid">
                              <PayButton
                                className="pay-button"
                                title={"paid"}
                                onClick={() => console.log("paid")}
                              />
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"10%"}
                          onClick={() =>
                            history.push(
                              "/dashboard/invoices-detail/" +
                                row.invoice_number,
                              {
                                row,
                              }
                            )
                          }
                        >
                          <ChevronRightIcon />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Pagiation
                invoicePagination={invoicePagination}
                onChange={onPageChange}
              />
            </div>
            <div className="web-invoice">
              <Table
                classes={{
                  root: classes.root,
                }}
                className="service-table"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow classes={{ root: classes.rowRroot }}>
                    {/* <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"20%"}
                    >
                      Customer Id.
                    </TableCell> */}
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"20%"}
                    >
                      Invoice No.
                    </TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"20%"}
                    >
                      Status
                    </TableCell>

                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"20%"}
                    >
                      Due Date
                    </TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"20%"}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"10%"}
                    ></TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"5%"}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow className={classes.leaderRow}>
                      <TableCell
                        className={classes.leaderCell}
                        align="left"
                        width={"100%"}
                        height={"200px"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!loading &&
                    invoiceListLoaded &&
                    invoiceList &&
                    invoiceList.map((row, index) => (
                      <TableRow
                        key={index}
                        classes={{ root: classes.rowRroot }}
                        onClick={() =>
                          history.push(
                            "/dashboard/invoices-detail/" + row.invoice_number,
                            {
                              row,
                            }
                          )
                        }
                      >
                        {/* <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"20%"}
                        >
                          {row.customerid}
                        </TableCell> */}
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"20%"}
                        >
                          {row.invoice_number}
                        </TableCell>
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"20%"}
                        >
                          {row.payment_status}
                        </TableCell>

                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"20%"}
                        >
                          {moment(row.due_date).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"20%"}
                        >
                          {formatter.format(row.amount.amount)}
                        </TableCell>

                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"10%"}
                        >
                          {row.payment_status === "unpaid" ? (
                            <div className="pay">
                              <PayButton
                                className="pay-button"
                                title={"Pay"}
                                onClick={() => console.log("pay")}
                              />
                            </div>
                          ) : (
                            <div className="paid">
                              <PayButton
                                className="pay-button"
                                title={"paid"}
                                onClick={() => console.log("paid")}
                              />
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"5%"}
                          onClick={() =>
                            history.push(
                              "/dashboard/invoices-detail/" +
                                row.invoice_number,
                              {
                                row,
                              }
                            )
                          }
                        >
                          <ChevronRightIcon />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Pagiation
                invoicePagination={invoicePagination}
                onChange={onPageChange}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }, [invoiceList, classes, loading, invoiceListLoaded, invoicePagination]);
};

export default Invoice;
