import Button from "@material-ui/core/Button";
import React from "react";
import "./RequestButton.scss";

export const RequestButton = (props) => {
  const { title } = props;
  return <Button {...props}>{title}</Button>;
};

export default RequestButton;
