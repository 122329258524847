import React from "react";
import "./SubmitButton.scss";
import Button from "@material-ui/core/Button";
export const SubmitButton = (props) => {
  const { title } = props;
  return (
    <Button className="submit-button" {...props}>
      {title}
    </Button>
  );
};

export default SubmitButton;
