import React, { Fragment } from "react";
import "./InputField.scss";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    margin: "5px 0px",
    "& .MuiFormLabel-root": {
      color: "#adadad",
    },
  },
  underline: {
    color: "#FFFFFF",
    borderBottom: "1px solid #347cff",
    "&:after": {
      borderBottom: "none",
    },
  },
  input: {
    color: "white",
  },
  focused: {
    color: "white",
  },
}));
export const InputField = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <TextField
        classes={{
          root: classes.root,
        }}
        {...props}
        InputProps={{
          className: classes.input,
          classes: {
            underline: classes.underline,
            focused: classes.focused,
          },
        }}
      />
    </Fragment>
  );
};

export default InputField;
