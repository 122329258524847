import React, { createContext, useContext, useState, useCallback } from "react";
import Api from "apirequests/AuthApi";
const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

const initialState = {
  email: "",
  password: "",
  isLogin: false,
  isOtpSent: false,
};
export const UserConsumer = UserContext.Consumer;

export function UserContextProvider({ children }) {
  const [state, setState] = useState(initialState);
  const [error, setError] = useState(undefined);
  const setStates = useCallback((nextState) => {
    setState((prevState) => {
      return nextState;
    });
  }, []);
  const checkLogin = () => {
    const userData = JSON.parse(localStorage.getItem("stt_user_data"));
    if (userData && userData.isLogin) {
      setStates(userData);
    }
  };
  const contactUs = async (body) => {
    const loginCredantial = new FormData();
    loginCredantial.append("name", body.name);
    loginCredantial.append("email", body.email);
    loginCredantial.append("phone_no", body.phone_no);
    loginCredantial.append("message", body.message);

    try {
      const res = await Api.submitContactUs(loginCredantial);
      if (res.Status === 200) setError("Request added successfully");
      setTimeout(() => {
        setError(undefined);
      }, 3000);
      return res;
    } catch (error) {
      const { status, data } = error.response;
      console.log(error.response);
      if (status === 400) {
        setError(data.Message);
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      }
    }
  };
  const login = async (body) => {
    const loginCredantial = new FormData();
    loginCredantial.append("username", body.username);
    loginCredantial.append("password", body.password);

    try {
      const res = await Api.login(loginCredantial);
      if (res.Status === 200)
        localStorage.setItem(
          "stt_user_data",
          JSON.stringify({ ...res.Results, isLogin: true })
        );

      setState({ ...res.Results, isLogin: true });
      return res;
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        setError(data.Message);
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      }
      return error.response.data;
    }
  };
  const resetPassword = async (email) => {
    const resetPasswordCridentials = new FormData();
    resetPasswordCridentials.append("email", email);

    try {
      const res = await Api.resetPassword(resetPasswordCridentials);
      const { success } = res;
      if (success) {
        setStates({ email, isOtpSent: true });
        setError({ type: "success", message: success });
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      }
    } catch (error) {
      const { data } = error.response;
      const { Status } = data;

      if (Status === 400) {
        setError({ type: "error", message: data.Message });
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      }
    }
  };
  const submitOtp = async (email, otp) => {
    const optCheckCridentials = new FormData();
    optCheckCridentials.append("email", email);
    optCheckCridentials.append("otp", otp);

    try {
      const res = await Api.submitOtp(optCheckCridentials);
      const { status, success } = res;
      if (status === 200) {
        setStates({ email, isOtpSent: true });
        setError({ type: "success", message: success });
        setTimeout(() => {
          setError(undefined);
        }, 3000);
        return res;
      }
    } catch (error) {
      const { Status, data } = error.response;
      if (Status === 400) {
        setError({ type: "error", message: data.Message });
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      }
    }
  };
  const changePassword = async (email, password) => {
    const changePasswordCridentials = new FormData();
    changePasswordCridentials.append("email", email);
    changePasswordCridentials.append("password", password);
    try {
      const res = await Api.changePassword(changePasswordCridentials);

      setStates(initialState);
      return res;
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        setError({ type: "error", message: data.Message });
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      }
    }
  };
  const logOut = () => {
    setStates(initialState);
    localStorage.removeItem("stt_user_data");
  };
  const value = {
    state,
    setStates,
    login: login,
    checkLogin: checkLogin,
    resetPassword: resetPassword,
    logOut: logOut,
    error: error,
    contactUs: contactUs,
    submitOtp: submitOtp,
    changePassword: changePassword,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
