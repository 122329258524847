import Api from "apirequests/DashBoardApi";
import React, { createContext, useCallback, useContext, useState } from "react";
const DashboardContext = createContext();
export function useDashboardContext() {
  return useContext(DashboardContext);
}
const default_pagination = {
  page: 1,
  pages: 10,
  per_page: 30,
};
const initialState = {
  order_pending: undefined,
  order_completed: undefined,
  orderLoaded: false,
  isLoading: false,
  serviceList: [],
  serviceListLoaded: false,
  orderList: [],
  orderListLoaded: false,
  orderDetail: null,
  freshbookUserDetail: undefined,
  invoiceList: undefined,
  invoiceListLoaded: false,
  invoiceDetail: null,
  invoiceDetailLoaded: false,
  invoiceDetailList: null,
  invoicePagination: default_pagination,
  tokenLoaded: false,
  home_detail: undefined,
  homeDetailLoaded: false,
  offsetKeys: {},
};

const config_data = {
  // 2 client_id: "9e1bac0c7b239b09b2cddb56e046af7bf7d044a03f3578a4da111e54cc75d0b1",
  // 3 client_id: "4c107c7d6cfaf15d48fb41cf08ef76267427dc36f4e04c81aba4cb5c3a22265f",
  // 4 client_id: "cb8d949343ab6c825d394218693d02f80f69a589af155c44ff923d4b90f5b83d",
  client_id: process.env.FB_API_client_id,
  // 2 client_secret: "f1550d30eb8eab82dc7114c449e8fbdfdbfe8699f81fa3a55d43557cf27e48f5",
  // 3 client_secret: "a336d5612a72150d4e7a05a2b06dc5f2bf343ab73f95f0df1c4c9271578721b4",
  // 4 client_secret: "3feef25019b22d1b378eb4eedbae298cfad3ad99de06c54781e7a9f01fad23dc",
  client_secret: process.env.FB_API_client_secret,
  //code: "89b0c84148f5ddd750fabd01d7d43c36c6cae1398f1df11294cc6b2ce0f7dfcd",
  // 2 code: "8eb0b3b906179f59298d3e86a0abda02b8a8da767cbfda3c47425943752bbba1",
  // 3 code: "a021cf7baa11a502db9b3fad25cfdde75bde87ca345fcde32f7fc8feb0081b25",
  // 4 code: "fead8150e88002b022a684061fee7f495c483daf33817281ad6389e2998fc69e",
  code: process.env.FB_API_code,
  //redirect_uri: "https://webhook.site/963a1f55-548d-407d-9802-2df70169a111",
  // 2 redirect_uri: "https://webhook.site/b186087f-13b6-48c9-b66c-1d7e7f934b76",
  // 3 redirect_uri: "https://webhook.site/7fdbdb38-5c87-4ca5-8c64-094332665faa",
  // 4 redirect_uri: "https://webhook.site/4285f1c8-c051-43c7-aec3-91f46cc3e267",
  redirect_uri: process.env.FB_API_redirect_uri,
};

let jobTypeList = [];
export const DashboardConsumer = DashboardContext.Consumer;

export function DashboardContextProvider({ children }) {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const setStates = useCallback((nextState) => {
    setState((prevState) => {
      return nextState;
    });
  }, []);
  const getAuthToken = async () => {
    setState({ ...state, tokenLoaded: true });
    try {
      const res = await Api.getAuthToken();
      setState({ ...state, tokenLoaded: true, authToken: res[0] });
      localStorage.setItem("freshbook_cridentials", JSON.stringify(res[0]));
    } catch (error) {
      const { data, status } = error.response;
      if (status === 401 && data.error === "unauthenticated") {
        getRefreshToken();
      }
    }
  };
  const getRefreshToken = async () => {
    const auth_data = JSON.parse(localStorage.getItem("freshbook_cridentials"));
    const body = {
      grant_type: "refresh_token",
      client_id: auth_data.client_id,
      client_secret: auth_data.client_secret,
      refresh_token: auth_data.refresh_token,
      redirect_uri: config_data.redirect_uri,
    };
    const res = await Api.getRefreshToken(body);
    const updatedToken = {
      ...auth_data,
      refresh_token: res.refresh_token,
      access_token: res.access_token,
    };
    setState({ ...state, authToken: updatedToken });
    localStorage.setItem("freshbook_cridentials", JSON.stringify(res));

    let updateBody = new FormData();
    updateBody.append("access_token", res.access_token);
    updateBody.append("refresh_token", res.refresh_token);
    await Api.updateRefreshToken(updateBody);
  };
  const getAccountDetail = async (access_token) => {
    const header = {
      Authorization: "Bearer " + access_token,
    };
    try {
      const userDetail = await Api.getUserDetail(header);
      const detail = userDetail.response;
      setState({ ...state, freshbookUserDetail: detail });
    } catch (error) {
      const { data, status } = error.response;
      if (status === 401 && data.error === "unauthenticated") {
        getRefreshToken();
      }
    }
  };
  const getCustumerName = async (customerId) => {
    const res = await Api.getCustomerName(customerId);
    return res.fields.Name;
  };
  const getCompletedOrders = async (customerId) => {
    try {
      const customer_name = await getCustumerName(customerId);
      const res = await Api.getOrdersCount(customer_name);
      const { records } = res;
      let order_pending = 0;
      let order_completed = 0;
      records.forEach((element) => {
        const { fields } = element;
        if (fields["STATUS"] === "COMPLETED") {
          order_completed = order_completed + 1;
        }
        if (!fields["STATUS"] || fields["STATUS"] === "ACTIVE") {
          order_pending = order_pending + 1;
        }
      });
      setState({
        ...state,
        orderList: [],
        orderListLoaded: false,
        orderLoaded: true,
        order_pending,
        order_completed,
      });
      return state;
    } catch (error) {
      console.log("error", error);
    }
  };
  const getServices = async () => {
    try {
      const res = await Api.getServiceList();
      setState({ ...state, serviceList: res, serviceListLoaded: true });
    } catch (error) {
      console.log(error);
    }
  };
  const getJobTypes = async (offset) => {
    let jobList = await Api.getAllJobType(offset);
    jobTypeList.push(...jobList.records);
    if (jobList.offset) {
      return getJobTypes(jobList.offset);
    }
    return jobTypeList;
  };
  const clearOrderList = () => {
    setStates({
      ...state,
      orderList: [],
      orderListLoaded: false,
      offsetKeys: {},
    });
  };
  const getOrderList = async (customerId, page = 1, offsetid = 0) => {
    setStates({
      ...state,
      orderList: [],
    });
    setLoading(true);

    try {
      const jobList = await getJobTypes();
      const customer_name = await getCustumerName(customerId);
      const res = await Api.getOrderList(customer_name, offsetid);
      const { records, offset } = res;
      let recordsUpdated = [];
      records.forEach(async (item, index) => {
        const { fields } = item;
        try {
          if (fields.SERVICE_CUST && fields.SERVICE_CUST[0]) {
            let data = jobList.find(
              (item) => item.id === fields.SERVICE_CUST[0]
            );
            if (res) {
              recordsUpdated.push({
                ...fields,
                SERVICE_SHORT: data.fields.Service_Short,
                id: item.id,
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
      let keys = state.offsetKeys;

      if (page === 1) {
        keys[page] = undefined;
        keys[page + 1] = offset;
      } else if (offset) {
        keys[page] = offset;
      }
      setStates({
        ...state,
        offset: offset,
        orderList: recordsUpdated,
        orderListLoaded: true,
        offsetKeys: keys,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrderDetail = async (id, service_cust) => {
    try {
      let orderDeatilData = {};
      let orderDetail = await Api.getOrderDetail(id);
      let serviceDetail = await Api.getServiceType(service_cust);
      orderDeatilData = {
        ...orderDetail.fields,
        createdTime: orderDetail.createdTime,
        Service_Short: serviceDetail.fields.Service_Short,
      };
      setStates({
        ...state,
        orderDetail: orderDeatilData,
        orderListLoaded: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getInvoices = async (
    access_token,
    account_id,
    freshbook_customerid
  ) => {
    const header = {
      Authorization: "Bearer " + access_token,
    };

    setState({ ...state, invoiceDetailList: null });
    setLoading(true);
    try {
      const invoices = await Api.getInvoices(
        header,
        account_id,
        freshbook_customerid,
        100
      );
      const { result } = invoices.response;
      let home_detail = {};
      if (result.invoices.length > 0) {
        let current_balance = 0;
        result.invoices.forEach((item) => {
          if (item.payment_status === "unpaid")
            current_balance =
              current_balance + parseFloat(item.amount.amount, 2);
        });
        home_detail = {
          payment_due: result.invoices[0].amount.amount,
          due_date: result.invoices[0].due_date,
          current_balance: current_balance,
        };
      }
      setState({
        ...state,
        home_detail,
        homeDetailLoaded: true,
      });
      setLoading(false);
    } catch (error) {
      const { data, status } = error.response;
      if (status === 401 && data.error === "unauthenticated") {
        getRefreshToken();
      }
    }
  };
  const getInvoiceList = async (
    access_token,
    account_id,
    page_number = 1,
    freshbook_customerid
  ) => {
    const header = {
      Authorization: "Bearer " + access_token,
    };

    const page_size = default_pagination.per_page;
    setState({ ...state, invoiceDetailList: null });
    setLoading(true);
    try {
      const invoiceList = await Api.getInvoiceList(
        header,
        account_id,
        page_number,
        page_size,
        freshbook_customerid
      );
      const { result } = invoiceList.response;
      const { invoices, page, pages, per_page, total } = result;
      const invoicePagination = {
        page,
        pages,
        per_page,
        total,
      };
      setStates({
        ...state,
        invoiceList: invoices,
        invoicePagination: invoicePagination,
        invoiceListLoaded: true,
        invoiceDetailList: undefined,
        orderList: [],
        orderListLoaded: false,
      });
      setLoading(false);
    } catch (error) {
      const { data, status } = error.response;
      if (status === 401 && data.error === "unauthenticated") {
        getRefreshToken();
      }
    }
  };
  const getInvoiceDetail = async (id) => {
    setStates({
      ...state,
      invoiceDetailList: null,
      invoiceDetailLoaded: true,
    });
    try {
      let jobList = await getJobTypes();
      const invoiceOrderList = await Api.getInvoiceOrders(id);

      const { records } = invoiceOrderList;
      let recordsUpdated = [];
      records.forEach(async (item) => {
        const { fields } = item;
        if (fields.SERVICE_CUST && fields.SERVICE_CUST[0]) {
          let data = jobList.find((item) => item.id === fields.SERVICE_CUST[0]);
          if (invoiceOrderList) {
            recordsUpdated.push({
              ...fields,
              SERVICE_SHORT: data.fields.Service_Short,
            });
          }
        }
      });
      setStates({
        ...state,
        invoiceDetailList: recordsUpdated,
        invoiceDetailLoaded: false,
      });
    } catch (error) {
      const { data, status } = error.response;
      if (status === 401 && data.error === "unauthenticated") {
        getRefreshToken();
      }
    }
  };
  const claerDashbord = () => {
    setStates({
      ...initialState,
    });
  };

  const value = {
    state,
    setStates,
    loading,
    getServices: getServices,
    getCompletedOrders: getCompletedOrders,
    getOrderList: getOrderList,
    getOrderDetail: getOrderDetail,
    getAuthToken: getAuthToken,
    getRefreshToken: getRefreshToken,
    getAccountDetail: getAccountDetail,
    getInvoices: getInvoices,
    getInvoiceList: getInvoiceList,
    getInvoiceDetail: getInvoiceDetail,
    claerDashbord: claerDashbord,
    clearOrderList: clearOrderList,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}
