import axios from "axios";

export const api = {
  header: (type) => {
    let header = {};
    if (type === "freshbook") {
      header = {
        "Content-Type": "application/json",
      };
    }
    if (type === "default") {
      header = {
        "Content-Type": "multipart/form-data",
      };
    }
    return header;
  },

  getMethod: (baseUrl, url, apiKey, header) => {
    var headers = api.header();
    if (header) {
      headers = { ...header };
    } else if (apiKey) {
      headers = { ...headers, Authorization: "Bearer " + apiKey };
    }
    return new Promise((resolve, reject) => {
      axios
        .get(baseUrl + url, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject(err);
          } else {
            reject(err);
          }
        });
    });
  },

  deleteMethod: (baseUrl, url) => {
    var headers = api.header();
    return new Promise((resolve, reject) => {
      axios
        .delete(baseUrl + url, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  patchMethod: (baseUrl, url, data, type = "default") => {
    var headers = api.header(type);
    return new Promise((resolve, reject) => {
      axios
        .patch(baseUrl + url, data, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  postMethod: (baseUrl, url, data, header) => {
    var headers = api.header(header);
    return new Promise((resolve, reject) => {
      axios
        .post(baseUrl + url, data, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  putMethod: (baseUrl, url, data) => {
    var headers = api.header();
    return new Promise((resolve, reject) => {
      axios
        .put(baseUrl + url, data, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
