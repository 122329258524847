import InputField from "components/InputField/InputField";
import SubmitButton from "components/SubmitButton/SubmitButton";
import React, { Fragment, useState, useMemo, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import "./ResetPassword.scss";
import { useUserContext } from "contexts";
import { useHistory } from "react-router-dom";
import { changeTitle } from "helpers/helperFunctions";
export const NewPassword = (props) => {
  const UserContext = useUserContext();
  const history = useHistory();
  const [formData, setformData] = useState({ password1: "", password2: "" });
  const [passwordError, setPasswordError] = useState(undefined);
  const userProps = useMemo(() => {
    return {
      state: UserContext.state,
      changePassword: UserContext.changePassword,
      error: UserContext.error,
    };
  }, [UserContext]);
  const { state, error, changePassword } = userProps;
  const { email } = state;
  function handleChange(e) {
    setformData({ ...formData, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (formData.password1 !== formData.password2) {
      setPasswordError({
        type: "error",
        message: "Please enter same password",
      });
    } else {
      setPasswordError(undefined);
      const res = await changePassword(email, formData.password2);
      const { status } = res;
      if (status === 200) {
        history.push("/auth/login");
      }
    }
  }
  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);
  return useMemo(() => {
    return (
      <Fragment>
        <span className="inner-title">Enter password</span>
        <div className="form-container">
          {error && <Alert severity={error.type}>{error.message}</Alert>}
          {passwordError && (
            <Alert severity={passwordError.type}>{passwordError.message}</Alert>
          )}
          <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="login-form">
              <InputField
                type="password"
                value={formData.password1}
                name="password1"
                onChange={(e) => handleChange(e)}
                id="username"
                label="Enter Password"
                required
              />
              <InputField
                type="password"
                id="otp"
                value={formData.password2}
                name="password2"
                onChange={(e) => handleChange(e)}
                label="Re-enter password"
                required
              />

              <div className="submit-container">
                <SubmitButton
                  type="submit"
                  title={"Change"}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }, [error, formData, passwordError]);
};

export default NewPassword;
