import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { Fragment, useMemo, useEffect, useState } from "react";
import { useDashboardContext } from "contexts";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { OrderPagination } from "components/OrderPagination";
import moment from "moment";
import "./Orders.scss";
import { changeTitle } from "helpers/helperFunctions";
const useStyles = makeStyles({
  root: {
    background: "#000000",
    color: "#ffffff",
    display: "flex",
    flexDirection: "column",
  },
  titleCell: {
    fontFamily: "Helvetica",
    fontSize: " 14px",
    fontWeight: "700",
  },
  leaderRow: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  leaderCell: {
    display: "flex",
    justifyContent: "center",
    border: "none",
  },
  headerCell: {
    color: "#ffffff",
    fontFamily: "Helvetica",
    fontSize: "16px",
    padding: "5px 10px",
    border: "none",
    fontWeight: "700",
  },
  bodyCell: {
    color: "#ffffff",
    fontFamily: "Helvetica",
    fontSize: "14px",
    border: "none",
    cursor: "pointer",
    padding: "5px 10px",
  },
  mobileRowRoot: {
    border: "1px solid #666666",
    borderRadius: "6px",
    marginTop: "5px",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
  },
  rowRroot: {
    height: "40px",
    border: "1px solid #666666",
    borderRadius: "6px",
    marginTop: "5px",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
  },
});

export const Orders = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const DashBoardContext = useDashboardContext();

  const onPageChange = (page) => {
    history.push({
      pathname: "/dashboard/orders",
      search: `?page=${page}`,
    });
  };
  const dashboardProps = useMemo(() => {
    return {
      state: DashBoardContext.state,
      getOrderList: DashBoardContext.getOrderList,
      loading: DashBoardContext.loading,
      clearOrderList: DashBoardContext.clearOrderList,
    };
  }, [DashBoardContext]);

  const { getOrderList, clearOrderList, state, loading } = dashboardProps;

  const [userData] = useState(
    JSON.parse(localStorage.getItem("stt_user_data"))
  );
  const { orderList, offset, offsetKeys, orderListLoaded } = state;
  const { airtable_customer } = userData;

  let params = history.location.search;
  useEffect(() => {
    let page = parseInt(params.split("=")[1]);
    let key_offset = offsetKeys[page];
    if (page > 1 && key_offset) {
      getOrderList(airtable_customer, page + 1, key_offset);
    }
    if (!key_offset && orderListLoaded) {
      getOrderList(airtable_customer);
    }
  }, [params]);

  useEffect(() => {
    if (!orderListLoaded && !loading) {
      getOrderList(airtable_customer);
    }
  }, [orderListLoaded]);

  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);
  useEffect(() => {
    return () => {
      clearOrderList("order");
    };
  }, []);
  function nextPage() {
    if (offset) {
      let page = params.split("=")[1];
      onPageChange(parseInt(page) + 1);
    }
  }
  function prevPage() {
    let page = parseInt(params.split("=")[1]);
    if (page > 1) onPageChange(parseInt(page) - 1);
  }
  return useMemo(() => {
    return (
      <Fragment>
        <div className="orders-container">
          <div className="orders-list-container">
            <div className="mobile-order">
              <Table
                classes={{
                  root: classes.root,
                }}
                className="service-table"
                aria-label="simple table"
              >
                <TableBody>
                  {loading && (
                    <TableRow className={classes.leaderRow}>
                      <TableCell
                        className={classes.leaderCell}
                        align="left"
                        width={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    orderListLoaded &&
                    orderList &&
                    orderList.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          classes={{ root: classes.mobileRowRoot }}
                          onClick={() =>
                            history.push("/dashboard/order-detail/" + row.id, {
                              row,
                            })
                          }
                        >
                          <TableCell
                            className={classes.bodyCell}
                            align="left"
                            width={"90%"}
                          >
                            <div>
                              {row.STATUS === "COMPLETED" ? (
                                <span id="status-completed">{row.STATUS}</span>
                              ) : (
                                <span id="status-progress">IN PROGRESS</span>
                              )}
                            </div>
                            <div>{row.TITLE}</div>

                            <div>{row.SERVICE_SHORT}</div>

                            <div>
                              {" "}
                              {moment(row.Created).format("YYYY-MM-DD")}
                            </div>
                          </TableCell>

                          <TableCell
                            className={classes.bodyCell}
                            align="left"
                            onClick={() =>
                              history.push(
                                "/dashboard/order-detail/" + row.id,
                                {
                                  row,
                                }
                              )
                            }
                          >
                            <ChevronRightIcon />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <OrderPagination nextPage={nextPage} prevPage={prevPage} />
            </div>
            <div className="web-order">
              <Table
                classes={{
                  root: classes.root,
                }}
                className="service-table"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow classes={{ root: classes.rowRroot }}>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"10%"}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"50%"}
                    >
                      Title
                    </TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"20%"}
                    >
                      Job Type
                    </TableCell>

                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"20%"}
                    >
                      Submission Date
                    </TableCell>
                    <TableCell
                      className={classes.headerCell}
                      align="left"
                      width={"5%"}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow className={classes.leaderRow}>
                      <TableCell
                        className={classes.leaderCell}
                        align="left"
                        width={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    orderListLoaded &&
                    orderList &&
                    orderList.map((row, index) => (
                      <TableRow
                        key={index}
                        classes={{ root: classes.rowRroot }}
                        onClick={() =>
                          history.push("/dashboard/order-detail/" + row.id, {
                            row,
                          })
                        }
                      >
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"10%"}
                        >
                          {row.STATUS === "COMPLETED" ? (
                            <span id="status-completed">{row.STATUS}</span>
                          ) : (
                            <span id="status-progress">IN PROGRESS</span>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.bodyCell}
                          classes={{
                            root: classes.titleCell,
                          }}
                          align="left"
                          width={"50%"}
                        >
                          {row.TITLE ? row.TITLE : "-"}
                        </TableCell>
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"20%"}
                        >
                          {row.SERVICE_SHORT}
                        </TableCell>

                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"20%"}
                        >
                          {row.Created
                            ? moment(row.Created).format("YYYY-MM-DD")
                            : "-"}
                        </TableCell>
                        <TableCell
                          className={classes.bodyCell}
                          align="left"
                          width={"5%"}
                        >
                          <ChevronRightIcon />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <OrderPagination nextPage={nextPage} prevPage={prevPage} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }, [orderList, classes, loading, orderListLoaded]);
};

export default Orders;
