import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import icon_logout from "assets/icon_logout.svg";
import icon_profhome from "assets/icon_profhome.svg";
import icon_settings from "assets/icon_settings.svg";
import logo from "assets/logo.svg";
import ProfButton from "assets/ProfButton.svg";
import { MenuDrawer } from "components/MenuDrawer";
import { RequestButton } from "components/RequestButton";
import { useDashboardContext, useUserContext } from "contexts";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import "./NavBar.scss";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#000000",
    height: "auto",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export const NavBar = (props) => {
  const classes = useStyles();
  const UserContext = useUserContext();
  const DashboardContext = useDashboardContext();
  const dashboardProps = useMemo(() => {
    return {
      claerDashbord: DashboardContext.claerDashbord,
      dashboardState: DashboardContext.state,
    };
  }, [DashboardContext]);
  const userProps = useMemo(() => {
    return {
      isLogin: UserContext.state.isLogin,
      logOut: UserContext.logOut,
      state: UserContext.state,
    };
  }, [UserContext]);
  const { claerDashbord } = dashboardProps;
  const { isLogin, logOut } = userProps;
  const [anchor, setAnchor] = useState({ left: false });
  const [showProfile, setShowProfile] = useState(false);
  const history = useHistory();

  const toggleDrawer = (anchor, open) => {
    setAnchor({ [anchor]: open });
  };
  const handleProfileMenu = (show) => {
    setShowProfile(show);
  };
  const handleLogout = useCallback(() => {
    claerDashbord();
    logOut();
    handleProfileMenu(false);
    history.push("/auth");
  }, [history, claerDashbord, logOut]);

  const goToHome = (path) => {
    handleProfileMenu(false);
    history.push(path);
  };
  return useMemo(() => {
    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.root}>
          <Toolbar>
            <div className="slider">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => toggleDrawer("left", true)}
              >
                <MenuIcon />
              </IconButton>
              <MenuDrawer
                anchor={"left"}
                state={anchor}
                toggleDrawer={toggleDrawer}
                claerDashbord={claerDashbord}
              />
            </div>
            <div className="header-logo" onClick={() => goToHome("/home")}>
              <img className="logo" src={logo} alt={"logo"} />
            </div>
            <div className="header-buttons">
              {!isLogin && (
                <RequestButton
                  title={"Get Captions"}
                  width={50}
                  onClick={() => history.push("/contact-us")}
                  className="request-button"
                />
              )}
              <div className="header-nav-button">
                <Button
                  color="inherit"
                  onClick={() => history.push("/services")}
                >
                  Services
                </Button>{" "}
                {!isLogin && (
                  <Button
                    color="inherit"
                    onClick={() => history.push("/contact-us")}
                  >
                    Contact Us
                  </Button>
                )}
                {!isLogin ? (
                  <Button color="inherit" onClick={() => history.push("/auth")}>
                    Login
                  </Button>
                ) : (
                  <Fragment>
                    <div className="profile-div">
                      <Button
                        color="inherit"
                        onClick={() => handleProfileMenu(!showProfile)}
                      >
                        <img src={ProfButton} alt="profile-btn" />
                      </Button>
                      {showProfile && (
                        <div className="profile-section">
                          <div className="profile-btn">
                            <Button
                              className={"close-button"}
                              classes={{
                                root: classes.closeButton,
                                text: classes.closeButton,
                              }}
                              onClick={() => handleProfileMenu(!showProfile)}
                            >
                              <CloseIcon />
                            </Button>
                          </div>
                          <div className="action-section">
                            <Button
                              className={"slider-button"}
                              onClick={() => goToHome("/dashboard/home")}
                            >
                              <img src={icon_profhome} alt={"logo"} />
                              Client Home
                            </Button>{" "}
                            <Button className={"slider-button"}>
                              <img src={icon_settings} alt={"logo"} /> Account
                              Setting
                            </Button>{" "}
                            <Button
                              className={"slider-button"}
                              onClick={() => handleLogout()}
                            >
                              <img src={icon_logout} alt={"logo"} /> Logout
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }, [anchor, isLogin, classes, history, handleLogout, showProfile]);
};

export default NavBar;
