import { api } from "./api";
import config from "./config";
const baseId = "appSGvNoVC18hY6HE";
const apiKey = "keySKm1XkmrUEXMkI";

const dashboardApi = {
  getCustomerName: (customer_id) => {
    return api.getMethod(
      config.AIR_TABLE_BASE_URL + "/" + baseId,
      "/Customers/" + customer_id,
      apiKey
    );
  },
  getOrdersCount: (customer_name) => {
    return api.getMethod(
      config.AIR_TABLE_BASE_URL + "/" + baseId,
      `/Tracker?view=FULL&filterByFormula=CUSTOMER="${customer_name}"`,
      apiKey
    );
  },
  getServiceList: () => {
    return api.getMethod(config.BASE_URL, "users/servicelist");
  },
  getOrderList: (customer_name, offset = 0) => {
    return api.getMethod(
      config.AIR_TABLE_BASE_URL + "/" + baseId,
      `/Tracker?fields%5B%5D=STTWO&fields%5B%5D=INVOICE&fields%5B%5D=Created&fields%5B%5D=INVOICE STATUS&fields%5B%5D=TEMPLATE&fields%5B%5D=STATUS&fields%5B%5D=SERVICE_CUST&&fields%5B%5D=DUE&fields%5B%5D=CUSTOMER&fields%5B%5D=TITLE&view=FULL&filterByFormula=CUSTOMER="${customer_name}"&offset=${offset}&sort%5B0%5D%5Bfield%5D=Created&field%5D=DUE&sort%5B0%5D%5Bdirection%5D=desc&pageSize=50`,
      apiKey
    );
  },
  getOrderDetail: (id) => {
    return api.getMethod(
      config.AIR_TABLE_BASE_URL + "/" + baseId,
      `/Tracker/${id}`,
      apiKey
    );
  },
  getAllJobType: (offset = 0) => {
    return api.getMethod(
      config.AIR_TABLE_BASE_URL + "/" + baseId,
      `/Customer Service Rates/?&offset=${offset}`,
      apiKey
    );
  },
  getJobType: (SERVICE_CUST) => {
    return api.getMethod(
      config.AIR_TABLE_BASE_URL + "/" + baseId,
      `/Customer Service Rates/${SERVICE_CUST}`,
      apiKey
    );
  },
  getServiceType: (service_cust = "") => {
    return api.getMethod(
      config.AIR_TABLE_BASE_URL + "/" + baseId,
      `/Customer Service Rates/${service_cust}`,
      apiKey
    );
  },
  getAuthToken: () => {
    return api.getMethod(config.BASE_URL, "users/freshbooklist");
  },
  getRefreshToken: (body) => {
    return api.postMethod(
      config.FRESHBOOK_URL,
      "auth/oauth/token",
      body,
      "freshbook"
    );
  },
  updateRefreshToken: (body) => {
    return api.postMethod(
      config.BASE_URL,
      "users/freshbook?freshbook=freshbook",
      body
    );
  },
  getUserDetail: (header) => {
    return api.getMethod(
      config.FRESHBOOK_URL,
      "auth/api/v1/users/me",
      "",
      header
    );
  },
  getInvoices: (header, account_id, freshbook_customerid, page_size) => {
    return api.getMethod(
      config.FRESHBOOK_URL,
      `accounting/account/${account_id}/invoices/invoices?per_page=${page_size}&search[display_status]=sent&search[customerid]=${freshbook_customerid}`,
      "",
      header
    );
  },
  getInvoiceList: (
    header,
    account_id,
    page,
    page_size,
    freshbook_customerid
  ) => {
    return api.getMethod(
      config.FRESHBOOK_URL,
      `accounting/account/${account_id}/invoices/invoices?page=${page}&page_size=${page_size}&search[customerid]=${freshbook_customerid}`,
      "",
      header
    );
  },
  getInvoiceOrders: (invoice_number) => {
    return api.getMethod(
      config.AIR_TABLE_BASE_URL + "/" + baseId,
      `/Tracker?filterByFormula=({Invoice} = ${invoice_number})`,
      apiKey
    );
  },
  getInvoiceDetail: (header, account_id, invoice_id) => {
    return api.getMethod(
      config.FRESHBOOK_URL,
      `accounting/account/${account_id}/invoices/invoices/${invoice_id}`,
      "",
      header
    );
  },
};

export default dashboardApi;
