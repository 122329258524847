import { UserContextProvider } from "contexts";
import Routes from "navigation/Routes";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ReactGA from 'react-ga';
//import auth from './auth.ts'; // Sample authentication provider

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <Routes />
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const trackingId = "UA-214274955-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
//ReactGA.set({
//  userId: auth.currentUserId(),
  // any data that is relevant to the user session
  // that you would like to track with google analytics
//})
