import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Data from "assets/Data.json";
import { useDashboardContext } from "contexts";
import React, { Fragment, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Services.scss";
import { changeTitle } from "helpers/helperFunctions";
const useStyles = makeStyles({
  root: {
    background: "#000000",
    color: "#ffffff",
  },
  headerCell: {
    color: "#ffffff",
    borderBottom: "1px solid #8c8c8c",
    fontFamily: "Helvetica",
    fontSize: "16px",
  },
  bodyCell: {
    color: "#ebac2f",
    border: "none",
    fontFamily: "Helvetica",
    fontSize: "16px",
    padding: "5px",
  },
  rowRroot: {
    height: "10px",
  },
});
export const Services = (props) => {
  const classes = useStyles();
  const DashBoardContext = useDashboardContext();
  const history = useHistory();
  const dashboardProps = useMemo(() => {
    return {
      state: DashBoardContext.state,
      getServices: DashBoardContext.getServices,
    };
  }, [DashBoardContext]);
  const { state, getServices } = dashboardProps;
  const { serviceList, serviceListLoaded } = state;
  useEffect(() => {
    if (!serviceListLoaded) getServices();
  }, [serviceListLoaded, getServices]);
  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);
  return useMemo(() => {
    return (
      <Fragment>
        <div className="container">
          <span className="inner-title">
            {Data && Data.serviceData && Data.serviceData.title}
          </span>
          <p className="inner-text">
            {Data && Data.serviceData && Data.serviceData.text}
          </p>
          <div className="service-list">
            <Table
              classes={{
                root: classes.root,
              }}
              className="service-table"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell} align="left">
                    Service
                  </TableCell>
                  <TableCell className={classes.headerCell} align="left">
                    Delivery
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceList.map((row, index) => (
                  <TableRow key={index} classes={{ root: classes.rowRroot }}>
                    <TableCell className={classes.bodyCell} align="left">
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.bodyCell} align="left">
                      {row.delivery}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <p className="inner-text-bottom">
            {Data && Data.serviceData && Data.serviceData.text1}
          </p>
        </div>
      </Fragment>
    );
  }, [serviceList, classes]);
};

export default Services;
