import React, { Fragment, useMemo } from "react";
import "./MenuDrawer.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { useUserContext } from "contexts";
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "245px",
    height: "352px",
    background: "#ebac2f",
  },
  sliderInner: {
    display: "flex",
    flexDirection: "column",
  },
  sliderButton: {
    display: "flex",
    height: "28px",
    alignItems: "center",
    fontFamily: "Arial",
    fontSize: "18px",
    textAlign: "left",
    color: "#000000",
  },
  closeButton: {
    width: "30px",
  },
}));
export const MenuDrawer = (props) => {
  const { anchor, state, toggleDrawer, claerDashbord } = props;
  const history = useHistory();
  const classes = useStyles();
  const UserContext = useUserContext();
  const userProps = useMemo(() => {
    return {
      isLogin: UserContext.state.isLogin,
      logOut: UserContext.logOut,
    };
  }, [UserContext]);
  const { isLogin, logOut } = userProps;
  const handleLogout = () => {
    logOut();
    claerDashbord();
    toggleDrawer(anchor, false);
    history.push("/auth");
  };
  const changePage = (pathname) => {
    history.push(pathname);
    toggleDrawer(anchor, false);
  };
  return (
    <Fragment>
      <SwipeableDrawer
        anchor={anchor}
        open={state[anchor]}
        onClose={() => toggleDrawer(anchor, false)}
        onOpen={() => toggleDrawer(anchor, true)}
        classes={{
          paper: classes.drawer,
        }}
      >
        <div className={classes.sliderInner}>
          <Button
            className={"close-button"}
            classes={{ root: classes.closeButton, text: classes.closeButton }}
            onClick={() => toggleDrawer(anchor, false)}
          >
            <CloseIcon />
          </Button>

          {isLogin ? (
            <div>
              <Button
                className={"slider-button"}
                classes={{
                  root: classes.sliderButton,
                  text: classes.sliderButton,
                }}
                onClick={() => changePage("/dashboard/home")}
              >
                Client Home
              </Button>
              <Button
                className={"slider-button"}
                classes={{
                  root: classes.sliderButton,
                  text: classes.sliderButton,
                }}
              >
                Account Setting
              </Button>
              <Button
                className={"slider-button"}
                classes={{
                  root: classes.sliderButton,
                  text: classes.sliderButton,
                }}
                onClick={() => changePage("/services")}
              >
                Services
              </Button>
              <Button
                className={"slider-button"}
                classes={{
                  root: classes.sliderButton,
                  text: classes.sliderButton,
                }}
                onClick={() => handleLogout()}
              >
                Logout
              </Button>
            </div>
          ) : (
            <div>
              <Button
                className={"slider-button"}
                classes={{
                  root: classes.sliderButton,
                  text: classes.sliderButton,
                }}
                onClick={() => changePage("/contact-us")}
              >
                Contact Us
              </Button>
              <Button
                className={"slider-button"}
                classes={{
                  root: classes.sliderButton,
                  text: classes.sliderButton,
                }}
                onClick={() => changePage("/services")}
              >
                Services
              </Button>
              <Button
                className={"slider-button"}
                classes={{
                  root: classes.sliderButton,
                  text: classes.sliderButton,
                }}
                onClick={() => changePage("/auth")}
              >
                Login
              </Button>
            </div>
          )}
        </div>
      </SwipeableDrawer>
    </Fragment>
  );
};

export default MenuDrawer;
