import { api } from "./api";
import config from "./config";
const authApi = {
  login: (body) => {
    return api.postMethod(config.BASE_URL, "users/login", body);
  },
  submitContactUs: (body) => {
    return api.postMethod(config.BASE_URL, "users/contactus", body);
  },
  resetPassword: (body) => {
    return api.postMethod(config.BASE_URL, "users/passwordreset", body);
  },
  submitOtp: (body) => {
    return api.postMethod(config.BASE_URL, "users/otpcheck", body);
  },
  changePassword: (body) => {
    return api.postMethod(config.BASE_URL, "users/setnewpassword", body);
  },
};

export default authApi;
