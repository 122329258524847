import React, { Fragment, useState, useEffect } from "react";
import "./Dashboard.scss";
import { Redirect, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Home from "screens/Dashboard/Home/Home";
import Orders from "screens/Dashboard/Orders/Orders";
import OrdersDetail from "screens/Dashboard/Orders/OrdersDetail";
import InvoiceDetail from "screens/Dashboard/Invoice/InvoiceDetail";
import Invoice from "screens/Dashboard/Invoice/Invoice";

const tabs = {
  tabId1: "home",
  tabId2: "orders",
  tabId3: "invoice",
};
export const Dashboard = (props) => {
  const history = useHistory();
  const { pathname } = history.location;
  const [tab, setTab] = useState(tabs.tabId1);

  useEffect(() => {
    if (pathname.includes("/dashboard/home")) {
      setTab(tabs.tabId1);
      activeTab(tabs.tabId1);
    }
    if (pathname.includes("/dashboard/orders")) {
      setTab(tabs.tabId2);
      activeTab(tabs.tabId2);
    }
    if (pathname.includes("/dashboard/invoice")) {
      setTab(tabs.tabId3);
      activeTab(tabs.tabId2);
    }
  }, [pathname]);
  function changeTab(tab, path, search) {
    setTab(tab);

    if (search) {
      history.push({
        pathname: "/dashboard" + path,
        search: `?page=${search}`,
      });
    } else {
      history.push("/dashboard" + path);
    }
  }
  function activeTab(activeTab) {
    let classes = "nav-item";
    if (tab === activeTab) {
      classes = classes + " active";
      return classes;
    } else {
      return classes;
    }
  }
  return (
    <Fragment>
      <div className="dashboard-main-container">
        <div className="dashboard-nav-bar">
          <ul className="nav-list">
            <li
              className={activeTab(tabs.tabId1)}
              onClick={() => changeTab(tabs.tabId1, "/home")}
            >
              Home
            </li>
            <li
              className={activeTab(tabs.tabId2)}
              onClick={() => changeTab(tabs.tabId2, "/orders", 1)}
            >
              Orders
            </li>
            <li
              className={activeTab(tabs.tabId3)}
              onClick={() => changeTab(tabs.tabId3, "/invoices")}
            >
              Invoice
            </li>
          </ul>
        </div>
        <div className="dashboard-component">
          <Switch>
            <Route exact path="/dashboard/home" render={() => <Home />} />
            <Route exact path="/dashboard/orders" render={() => <Orders />} />
            <Route
              exact
              path="/dashboard/order-detail/:id"
              render={({ match }) => {
                return <OrdersDetail id={match.params.id} />;
              }}
            />
            <Route
              exact
              path="/dashboard/invoices"
              render={() => <Invoice />}
            />
            <Route
              exact
              path="/dashboard/invoices-detail/:id"
              render={({ match }) => <InvoiceDetail id={match.params.id} />}
            />
            <Redirect exact={true} from="/dashboard" to="/dashboard/home" />
          </Switch>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
