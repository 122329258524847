export function changeTitle(pathname) {
  let title = "Standard Timed Text - ";
  if (pathname === "/dashboard/home") {
    title = title + "Client Home";
  }
  if (pathname === "/dashboard/orders") {
    title = title + "Client Orders";
  }
  if (pathname === "/dashboard/invoices") {
    title = title + "Client Invoices";
  }
  if (pathname.includes("/dashboard/order-detail")) {
    title = title + "Order Detail";
  }
  if (pathname.includes("/dashboard/invoices-detail")) {
    title = title + "Invoice Detail";
  }
  if (pathname === "/auth/login") {
    title = title + "Login";
  }
  if (pathname === "/services") {
    title = title + "Services";
  }
  if (pathname === "/auth/forgot-password") {
    title = title + "Reset Password";
  }
  if (pathname === "/auth/new-password") {
    title = title + "Update Password";
  }
  if (pathname === "/home") {
    title = title + "Home";
  }
  if (pathname === "/contact-us") {
    title = title + "Contact Us";
  }
  document.title = title;
}

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  const test = re.test(email.toLowerCase());
  return !test;
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
