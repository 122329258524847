import React from "react";
import "./Pagiation.scss";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
    },
    "& .Mui-selected": {
      backgroundColor: "rgb(124 142 183 / 56%)",
    },
  },
}));
export const Pagiation = (props) => {
  const classes = useStyles();
  const { invoicePagination, onChange } = props;
  const { page, pages } = invoicePagination;

  return (
    <div className={classes.root}>
      <Pagination
        classes={{ ul: classes.ul }}
        page={page}
        count={pages}
        shape="rounded"
        onChange={(e, page) => onChange(page)}
      />
    </div>
  );
};

export default Pagiation;
