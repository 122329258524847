import React from "react";
import "./Footer.scss";

export const Footer = (props) => {
  return (
    <div className="footer">
      <div className="footer-inner">
        <div>
          <span>&#169;</span> standardtimedtext.com &#160;&#160;{process.env.REACT_APP_ENV} 
        </div>
        <div> Terms | Privacy | Sitemap</div>
      </div>
    </div>
  );
};

export default Footer;
