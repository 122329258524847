import React from "react";
import "./PayButton.scss";

import Button from "@material-ui/core/Button";

export const PayButton = (props) => {
  const { title } = props;
  return <Button {...props}>{title}</Button>;
};

export default PayButton;
