import { Button } from "@material-ui/core";
import React from "react";
import "./OrderPagination.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "#ffffff",
    border: "1px solid #ffffff",
    margin: "5px",
  },
}));
export const OrderPagination = (props) => {
  const classes = useStyles();
  const { nextPage, prevPage } = props;
  return (
    <div className="pagination-container">
      <Button className={classes.root} onClick={() => prevPage()}>
        <ChevronLeftIcon />
        Prev{" "}
      </Button>
      <Button className={classes.root} onClick={() => nextPage()}>
        Next <ChevronRightIcon />
      </Button>
    </div>
  );
};

export default OrderPagination;
