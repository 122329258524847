const Url = {
  //BASE_URL: "http://18.117.221.146:8000/api/",
  //BASE_URL: "http://3.144.217.93:8000/api/",
  // Moving BASE_URL to an environment variable named REACT_APP_API_URL
  BASE_URL: process.env.REACT_APP_API_URL,
  AIR_TABLE_BASE_URL: "https://api.airtable.com/v0",
  FRESHBOOK_URL: "https://api.freshbooks.com/",
};

export default Url;
