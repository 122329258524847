import InputField from "components/InputField/InputField";
import SubmitButton from "components/SubmitButton/SubmitButton";
import React, { Fragment, useState, useMemo } from "react";
import Alert from "@material-ui/lab/Alert";
import "./ResetPassword.scss";
import { useUserContext } from "contexts";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { changeTitle } from "helpers/helperFunctions";
export const ResetPassword = (props) => {
  const UserContext = useUserContext();
  const history = useHistory();
  const [userData, setUserData] = useState({ email: "", otp: "" });
  const [isOtp, setIsOtp] = useState(false);
  const userProps = useMemo(() => {
    return {
      state: UserContext.state,
      resetPassword: UserContext.resetPassword,
      submitOtp: UserContext.submitOtp,
      error: UserContext.error,
    };
  }, [UserContext]);
  const { state, error, submitOtp, resetPassword } = userProps;
  function handleChange(e) {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    if (state.isOtpSent) {
      setIsOtp(true);
    }
  }, [state]);
  async function handleSubmit(e, type) {
    e.preventDefault();
    if (type === "email" && userData.email !== "") {
      resetPassword(userData.email);
    } else if (type === "otp") {
      const res = await submitOtp(userData.email, userData.otp);
      if (res.status === 200) {
        history.push("/auth/new-password");
      }
    }
  }
  useEffect(() => {
    const { pathname } = history.location;
    changeTitle(pathname);
  }, [history]);
  return useMemo(() => {
    return (
      <Fragment>
        <span className="inner-title">Password Reset</span>
        <div className="form-container">
          {error && <Alert severity={error.type}>{error.message}</Alert>}

          {!isOtp ? (
            <form autoComplete="off" onSubmit={(e) => handleSubmit(e, "email")}>
              <div className="login-form">
                <InputField
                  type="text"
                  value={userData.email}
                  name="email"
                  onChange={(e) => handleChange(e)}
                  id="username"
                  label="Username / Email"
                  required
                />

                <div className="submit-container">
                  <SubmitButton
                    type="submit"
                    title={state.isOtpSent ? "Submit" : "Reset"}
                    onClick={(e) => {
                      handleSubmit(e, "email");
                    }}
                    disabled={userData.email === ""}
                  />
                </div>
              </div>
            </form>
          ) : (
            <form autoComplete="off" onSubmit={(e) => handleSubmit(e, "otp")}>
              <div className="login-form">
                <InputField
                  type="text"
                  id="otp"
                  value={userData.otp}
                  name="otp"
                  onChange={(e) => handleChange(e)}
                  label="Enter Otp"
                  inputProps={{
                    maxLength: 6,
                  }}
                  required
                />
                <div className="submit-container">
                  <SubmitButton
                    type="submit"
                    title={state.isOtpSent ? "Submit" : "Reset"}
                    onClick={(e) => {
                      handleSubmit(e, "otp");
                    }}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </Fragment>
    );
  }, [error, userData]);
};

export default ResetPassword;
