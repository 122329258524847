import React, { Fragment } from "react";
import "./DetailBlock.scss";
import angleDoubleRight from "assets/angle-double-right.svg";
export const DetailBlock = (props) => {
  const { title, text } = props;
  return (
    <Fragment>
      <div className="detail-section">
        <div className="detail-header">
          <img src={angleDoubleRight} alt={"left"} />
          {title}
          <img src={angleDoubleRight} alt={"right"} />
        </div>

        <div className="detail-text">{text}</div>
      </div>
    </Fragment>
  );
};

export default DetailBlock;
